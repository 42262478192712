<template>

    
    <div class="variationWrap multiOption" v-if="question.is_multiple === 1" :class="[question.variations.length > 3 ? 'longVariations' : '']">

        <div class="variationRow" v-for="(variation,v) in question.variations">

            <input type="checkbox"
                   :name="questionName + '[]'"
                   :id="'id_variation_' + question.id + '_' + v"
                   :value="variation.id"
                   :true-value="variation.id"
                   v-model="question.answers"
                   :checked="question.answer == variation.id"
                   @change="updateQuestion(question)"
            >

            <label :for="'id_variation_' + question.id + '_' + v" v-html="variation.label + ' ' + '<i class=\'hoverFX\'></i>'"></label>

        </div> <!-- .for variation in question.variations -->

    </div>
    
    <div
            class="optionButtons radioButtons"
            :class="[question.variations.length > 3 ? 'selectArea' : '']"
            v-else
    >

        <chosen
                :options="question.variations"
                placeholder=""
                v-model="question.answer"
                class="no-chosen"
                label="label"
                track-by="id"
                :id="'id_'  + question.id"
                :name="questionName"
                v-if="question.variations.length > 3"

        ></chosen>

        <div class="labelButton" v-for="(variation,v) in question.variations" v-else>
            
            <input type="radio"
                   :name="questionName"
                   :id="'id_' + v + '_' + question.id"
                   :value="variation.id"
                   v-model="question.answer"
                   :checked="question.answer == variation.id"
                   @change="updateQuestion(question)"
            />

            <label 
                    :for="'id_' + v + '_' + question.id" 
                    v-html="variation.label"
            ></label>
            
        </div>

    </div> <!-- .optionButtons -->
    
</template>

<script>


import { props, computed, methods } from "../mixins/fields";

export default {
    name: "variations",
    mixins: [props, computed, methods],
    methods: {

        updateQuestion: _.debounce(function(question) {
            question = this.validate(question);
            this.$emit('update-question', question);
        }, 200),

        validate(question) {

            let canProceed = true;
            question.error_message = '';

            if(question.is_multiple === 1) {

                if (!question.answers.length && question.is_required) {
                    canProceed = false;
                }
                
            }else {
                
                if (!question.answer.length && question.is_required) {
                    canProceed = false;
                }

            }

            question.can_proceed = canProceed;
            question.can_proceed = this.canProceed(question, canProceed);

            return question;

        },
        
        canProceed(question) {

            if(question.cant_proceed_if.length) {

                let blocked = [];
                
                if(question.is_multiple === 1) {
                    
                    blocked = question.answers.filter(answer => {

                        return question.cant_proceed_if.indexOf(answer) > -1 ? question : false;

                    });
                    
                }else{
                    
                    if(question.cant_proceed_if.indexOf(question.answer) > -1) {
                        blocked.push(question);
                    }
                    
                }

                if(blocked.length) {
                    question.can_proceed = false;
                    question.error_message = 'Based on your answer, we cannot offer you medications for this condition.'
                }

            }
            
            return question.can_proceed;
            
        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .variationWrap.multiOption {
        text-align: left !important;
    }

    .questionWrap {
        
        .row {
            
            .labelButton {
                label {
                    border-radius: 59px !important;
                }
            }
            
            &.longVariations {

                display: block !important;
                max-width: 324px;

                .labelButton {
                    width: 100% !important;
                    padding: 0 !important;
                }

            }
            
            .selectArea {
                width: 100%;
                max-width: 300px;
            }
            
            .chosen-single {
                border-radius: 37px;
            }
            .chosen-container.chosen-container-active.chosen-with-drop .chosen-single {
                border-radius: 37px 37px 0 0;
            }
            
            .chosen-drop {
                border-radius: 0 0 37px 37px;
            }

        }

    }

</style>
