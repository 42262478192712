var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "categoryProducts" },
    [
      _vm.listTitle || _vm.listContent
        ? _c("div", { staticClass: "introBlock alignLeft" }, [
            _c("div", { staticClass: "innerIntro" }, [
              _vm.listTitle.length
                ? _c("div", {
                    staticClass: "introTitle",
                    domProps: { innerHTML: _vm._s(_vm.listTitle) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.listContent.length
                ? _c("div", {
                    staticClass: "introContentWrap",
                    domProps: { innerHTML: _vm._s(_vm.listContent) },
                  })
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("product-list", {
        attrs: {
          loading: _vm.loading,
          products: _vm.products,
          "parent-class": ["categoryProducts"],
          "list-class": ["categoryProductList"],
          "card-previews": true,
          minimal: false,
          "card-class": [],
          slider: true,
        },
      }),
      _vm._v(" "),
      _c("loading-spinner", {
        attrs: { loading: _vm.loading, "loading-text": "Loading Products" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }