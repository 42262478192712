<template>
    
  <main class="categoryProducts">

      <div class="introBlock alignLeft" v-if="listTitle || listContent">
          
          <div class="innerIntro">
              <div 
                      class="introTitle" 
                      v-html="listTitle"
                      v-if="listTitle.length"
              ></div>
              
              <div 
                      class="introContentWrap" 
                      v-html="listContent"
                      v-if="listContent.length"
              ></div>
          </div>
          
      </div>
    
      <product-list 
              :loading="loading" 
              :products="products" 
              :parent-class="['categoryProducts']" 
              :list-class="['categoryProductList']"
              :card-previews="true"
              :minimal="false"
              :card-class="[]"
              :slider="true"
      ></product-list>
    
   
      <loading-spinner
              :loading="loading"
              loading-text="Loading Products"
      ></loading-spinner>
    
      
  </main>
    
</template>

<script>

import productList from './product_list';

export default {
    name: "categoryProducts",
    components: {
        productList
    },
    props: {
        setProducts: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        listTitle: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        listContent: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            products: [],
            loading: false
        }
    },
    created() {
        this.products = this.setProducts;
    },
    methods: {
        
        hasProducts(products) {
            
            if(typeof products != 'undefined') {
                return products.length;
            }
            
            return false;
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .categoryProducts {

        position: relative;
        width: 100%;
        min-height: 400px;

        .loadingSpinner {
            position: absolute;
            top: 160px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
        }

        .splide__track {

            &:after {
                content: "";
                width: 257px;
                height: ~"calc(100% + 48px)";
                position: absolute;
                top: -24px;
                bottom: -24px;
                right: ~"calc(0px -  ((100vw - 100%) / 2))";
                background: linear-gradient(92deg, rgba(255, 255, 255, 0) 1.17%, rgba(255, 255, 255, 1) 71.03%);
            
            }

        }
        
    }
    
    .categoryProductList {

        float: none;
        clear: both;
        display: flex;
        width: ~"calc(100% + 40px)";
        margin: 0 -20px;
        gap: 0;
        
        li {
            flex: 0 0 33.333333%;
            padding: 0 20px;
        }


    }

</style>
