<template>

    <div class="assessmentBasketActions">

        <div class="basketErrors" v-if="errors.length">

            <div class="error basketAlert">
                <p v-html="errors[0]"></p>
            </div> <!-- .error -->

        </div> <!-- .basketErrors -->

        <div class="basketSuccess" v-else-if="success">

            <div class="success basketAlert">
                <p>
                    Your item(s) have been successfully added to your
                    <a href="/shop/checkout/basket.php">basket</a>
                </p>
            </div> <!-- .success -->

        </div> <!-- .basketSuccess -->

        <div class="basketButtons">

            <button
                    type="button"
                    class="button"
                    @click.prevent="addToBasket()"
                    v-html="label"
                    :disabled="buttonDisabled"
            ></button>

        </div> <!-- .basketButtons -->


    </div> <!-- .basketActions -->

</template>

<script>

import axios from 'axios';

export default {
    name: 'assessmentAddToBasket',
    components: {

    },
    props: {
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        }
    },
    data() {
        return {
            basketURL: '/shop/checkout/basket.php',
            label: 'Add to basket',
            directToBasket: true,
            quantity: 1,
            selectedSize: 0,
            errors: [],
            success: false,
            buttonDisabled: false,
            goToBasket: true //Go to basket on success, if true
        }
    },
    methods: {

        addToBasket() {

            const _this = this;

            this.setLoading(true, 'Adding to basket');
            this.buttonDisabled = true;

            const products = this.getGroupedProducts();
            
            if(products.length) {

                axios.post(this.urlAddToBasket, {
                    products_grouped: products,
                    assessment_ids: _this.$store.state.createdAssessments,
                })
                .then(function (response) {

                    const data = response.data;

                    if(data.success && !data.errors?.length) {
                        _this.success = true;
                        _this.errors = [];
                    }else{
                        _this.success = false;
                        _this.errors = data.errors;
                    }

                })
                .catch(function (error) {
                    _this.success = false;
                    _this.setLoading(false);
                    _this.errors = ['An unknown error occurred. Please try again later.'];
                })
                .finally(function () {

                    if(_this.success) {

                        if(_this.goToBasket) {

                            _this.setLoading(true, 'Added to basket');

                            setTimeout(() => {

                                if (!_.isUndefined(_this.$parent.$parent.$refs.mainForm)) {
                                    _this.$parent.$parent.$refs.mainForm.reset();
                                }
                                window.location = _this.basketURL;
                            }, 1000);

                        }else{
                            _this.setLoading(false);
                        }


                    }else{
                        _this.setLoading(false);
                    }

                    _this.buttonDisabled = false;
                });

            }else{
                _this.errors = ['No products have been selected'];
                _this.setLoading(false);
                _this.buttonDisabled = false;
            }

        },

        getGroupedProducts() {

            let productArr = [];

            for(const multiuserID in this.products) {

                let productsObj = {};

                let userName = this.getUsernameByID(multiuserID);

                let products = [];
                
                for(const productKey in this.products[multiuserID]) {
                    
                    let product =this.products[multiuserID][productKey];
                    
                    if(_.isUndefined(product.quantity)) {
                        product.quantity = 1;
                    }

                    products.push({
                        id: product.id,
                        name: product.name,
                        quantity: product.quantity,
                        minQuantity: product.minimum_quantity,
                    });
                }
                
                
                productsObj.products = products;
                productsObj.user_full_name = userName;

                productArr.push(productsObj);
            }

            return productArr;

        },

        getUsernameByID(multiuserID) {

            let userName = '';

            for(const uProp in this.$store.state.assessment.multiusers) {

                if(this.$store.state.assessment.multiusers[uProp].id == multiuserID) {

                    userName = this.$store.state.assessment.multiusers[uProp].name;

                }

            }

            return userName;

        },

        setLoading(loading, message) {

            if(this.$parent?.$parent?.$parent) {
                this.$parent?.$parent?.$parent.setLoading(loading, message);
            }

        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .assessmentBasketActions {

        .basketAlert {
            background: #fff !important;
        }

    }




</style>
