<template>

    <div class="productSizes">
        
        <template v-if="options.length > 1">

            <div class="productSize">
                
                <label 
                        v-if="selectLabel" 
                        v-html="selectLabel" 
                        :for="'select' + id"
                ></label>
    
                <div class="innerSelect">
                    
                    <chosen
                            :options="options"
                            v-model="selectedID"
                            :name="'size'"
                            @input="triggerChange()"
                            label="display_value"
                            trackBy="id"
                            placeholder=""
                            :id="'select' + id"
                    ></chosen>
    
                </div> <!-- .innerSelect -->

            </div> <!-- .productSize -->
            
        </template>
        
        <template v-else>
            <input 
                    type="hidden" 
                    name="size" 
                    v-model="selectedID" 
                    @input="triggerChange()"
            >
        </template>
        
    </div> <!-- .productSizes -->

</template>

<script>

import collect from "collect.js";

export default {
    name: 'productSizeSelect',
    props: {
        selectLabel: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        sizes: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        selectedSizeId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            selectedID: Number(0),
            id: ''
        }
    },
    watch: {
        selectedID: {
            handler(val, was) {
                if(was !== 0) {
                  
                    this.triggerSizeChange(val);
                    
                }
            }
        }
    },
    computed: {
        options() {
            return this.sortSizes();
        }
    },
    created() {
        this.setDefault();
        this.triggerChange();
    },
    mounted() {

        this.id = this._uid;
        
        this.setDefault();
        this.triggerChange();
    },
    methods: {
        setDefault() {
            if(this.sizes) {
                if (this.sizes.length) {
                    const sizes = this.sortSizes();
                    
                    if(this.selectedSizeId) {
                        this.selectedID = Number(this.selectedSizeId);
                    }else{
                        this.selectedID = Number(sizes[0].id);
                    }
                    
                }
            }
        },
        sortSizes() {
            const _this = this;

            let sizes = collect(this.sizes);

            sizes = sizes.map(size => {
                size.actual_price = _this.getPrice(size);
                size.display_price = _this.getCurrencySymbol() + _this.formatPrice(size.actual_price);
                size.display_value = size.size + ' (' + size.display_price + ')';
                return size;
            });

            let sorted = sizes.sortBy('actual_price');

            return sorted.toArray();
        },
        getPrice(option) {
            
            let price = parseFloat(option.price),
                    salePrice = parseFloat(option.sale_price);
            
            let outputPrice = price;
           
            if(salePrice > 0 && salePrice < price) {
                outputPrice = salePrice;
            }
 
            return this.formatPrice(outputPrice);
            
        },
        formatPrice(price) {
            price = parseFloat(price);
            return price.toFixed(2);
        },
        getCurrencySymbol() {
            return '£';
        },
        triggerChange() {
            
            this.$emit('change', this.selectedID);
        },
        triggerSizeChange(sizeID) {
            this.$emit('sizechanged', sizeID);
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .productSizes {
        padding: 0 0 15px;
    }

</style>
